import React, { useState } from 'react'
import certificate1 from "./img/certificate - ISO9001_2015.jpg"
import certificate2 from "./img/certificate - ISO14001_2015.jpg"
import certificate3 from "./img/certificate - ISO45001_2018.jpg"

import "./style/certificate.css"

let certificateSrc = '';
const Certificate = () => {
    const [popup, setPopup] = useState(false);
    

    const handleCertificateClick = (e) => {
        setPopup(!popup);
        console.log(e.target.src);
        certificateSrc = e.target.src;
    }

  return (
    <div className='certificate__container'>
        <div onClick={handleCertificateClick} className={`certificate__popup-overlay ${popup ? '_active' : "_hide"}`}>
            <div className="certificate__popup-wrapper">
                <img className='certificate__img' src={certificateSrc} alt="certificate" />
            </div>
        </div>

        <div className='certificate__box'>
            <img onClick={handleCertificateClick} className='certificate__img' src={certificate1} alt="certificate" />
        </div>

        <div className='certificate__box'>
            <img onClick={handleCertificateClick} className={`certificate__img`} src={certificate2} alt="certificate" />
        </div>

        <div className='certificate__box'>
            <img onClick={handleCertificateClick} className='certificate__img' src={certificate3} alt="certificate" />
        </div>
    </div>
  )
}

export default Certificate