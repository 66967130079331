import React from 'react';
import "./style/gallery.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';



function Gallery({selectedLanguage, languageTexts}) {
    return (
        <section className='gallery' id='gallery'>
            <h2 className="gallery__title secondary-title">
                {languageTexts[selectedLanguage].gallery.title}
            </h2>

            <div className="gallery__container">
                <Swiper
                    centeredSlides={true}
                    autoplay={{
                        delay: 400,
                        disableOnInteraction: false,
                        reverseDirection: true,
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    slidesPerView={4}
                    speed={1800}
                    direction= "horizontal"
                    breakpoints = {{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        570: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },

                        1000: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },

                        1800: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                        2000: {
                          slidesPerView: 4,
                          spaceBetween: 40
                        }
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide><div className="gallery-img gallery-img--1 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--2 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--3 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--4 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--5 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--6 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--7 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--8 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--9 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--10 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--11 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--12 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--13 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--14 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--15 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--16 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--17 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--18 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--19 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--20 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--21 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--22 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--23 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--24 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--25 swiper-slide"></div></SwiperSlide>
                    <SwiperSlide><div className="gallery-img gallery-img--26 swiper-slide"></div></SwiperSlide>
                </Swiper>
            </div>

            {/* <div className="gallery-navigation">
                <div className="gallery__arrow-left gallery-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M29.7695 11.89C28.9559 11.0764 27.6368 11.0764 26.8232 11.89L16.6411 22.0821C15.0153 23.7096 15.0159 26.3466 16.6424 27.9733L26.8307 38.1614C27.6443 38.9752 28.9634 38.9752 29.777 38.1614C30.5906 37.3479 30.5906 36.0287 29.777 35.2152L21.057 26.4952C20.2432 25.6816 20.2434 24.3625 21.057 23.5489L29.7695 14.8363C30.5831 14.0227 30.5831 12.7036 29.7695 11.89Z" fill="white"/>
                    </svg>
                </div>

                <div className="gallery__arrow-right gallery-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M20.2305 38.11C21.0441 38.9236 22.3632 38.9236 23.1768 38.11L33.3589 27.9179C34.9847 26.2904 34.9841 23.6534 33.3576 22.0267L23.1693 11.8386C22.3557 11.0248 21.0366 11.0248 20.223 11.8386C19.4094 12.6521 19.4094 13.9713 20.223 14.7848L28.943 23.5048C29.7568 24.3184 29.7566 25.6375 28.943 26.4511L20.2305 35.1637C19.4169 35.9773 19.4169 37.2964 20.2305 38.11Z" fill="white"/>
                    </svg>
                </div>
            </div> */}
        </section>
    );
}

export default Gallery;
