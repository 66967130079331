import React, { useEffect, useRef } from 'react'
import flagsImage from "./img/flags-img.webp"
import lavaImage from "./img/lava-img.svg"
import lavaImage2 from "./img/lava-img2.svg"
import "./style/about-us.css"

const AboutUs = ({languageTexts, setSelectedLanguage, selectedLanguage}) => {
  const elementRefs = {
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight) {
            element.classList.add('animate');
          }
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  return (
    <div className="about-us__wrapper">
      <img className="about-us__lava-img noselect" alt='about-us' src={lavaImage}/>


      <section id='about-us' className='about-us'>
        <div className="about-us__row">
          <div className="about-us__content" ref={elementRefs.left}>
            <div className="about-us__content-box">
              <div className="about-us__content-box--header">
                <h2 className='about-us__title secondary-title'>{languageTexts[selectedLanguage].about.title}</h2>

                <p className='about-us__experience-text'>
                  {languageTexts[selectedLanguage].about.desription1}
                </p>
              </div>
            </div>
       
            <div className="about-us__experience">
            <p className='about-us__text'>
              {languageTexts[selectedLanguage].about.subTitle}
                </p>
                <p className='about-us__text'>
                  {languageTexts[selectedLanguage].about.desription2} 
                </p>

                
            </div>
            <div className="about-us__content-box--footer">
                <img src={flagsImage} alt="Map" className="about-us__image" ref={elementRefs.right} />
              </div>
         
          </div>

  

        </div>
      </section>
    </div>
  )
}

export default AboutUs