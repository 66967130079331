import React from 'react'
import "./style/product-card.css"

const ProductCard = ({ refPath, srcPath, productName, modifier = "" }) => {
  return (
    <div ref={refPath} className={`product-card`}>
        <img src={srcPath} className='product-card__img' alt="img" />

        <h4 className={`product-card__title product-card__title--${modifier}`}>{productName}</h4>
    </div>
  )
}

export default ProductCard