import React, { useEffect, useRef, useState } from 'react';
import './style/FAQ.css';
import QuestionCard from '../UI/QuestionCard/QuestionCard';

const FAQ = ({ languageTexts, selectedLanguage }) => {
  const [cards, setCards] = useState([]);
  const [openCardIndex, setOpenCardIndex] = useState(null);

  useEffect(() => {
    setCards([  
      { question: languageTexts[selectedLanguage].faq.question1, answer: languageTexts[selectedLanguage].faq.answer1, isOpen: false, animationClass: "animation__left" },
      { question: languageTexts[selectedLanguage].faq.question2, answer: languageTexts[selectedLanguage].faq.answer2, isOpen: false, animationClass: "animation__right" },
      { question: languageTexts[selectedLanguage].faq.question3, 
        answer:`<ul>
          <li>${languageTexts[selectedLanguage].faq.answer3}</li>
          <li>${languageTexts[selectedLanguage].faq.answer3Description}</li>
        </ul>`, isOpen: false, animationClass: "animation__left" },
      { question: languageTexts[selectedLanguage].faq.question4, answer: languageTexts[selectedLanguage].faq.answer4, isOpen: false, animationClass: "animation__right" },
      { question: languageTexts[selectedLanguage].faq.question5, answer:`<ul>
          <li>${languageTexts[selectedLanguage].faq.answer5}</li>
          <li>${languageTexts[selectedLanguage].faq.answer5Description}</li>
        </ul>`, isOpen: false, animationClass: "animation__left" },
      { question: languageTexts[selectedLanguage].faq.question6, answer:`<ul>
          <li>${languageTexts[selectedLanguage].faq.answer6}</li>
          <li>${languageTexts[selectedLanguage].faq.answer6Description1}</li>
          <li>${languageTexts[selectedLanguage].faq.answer6Description2}</li>
        </ul>`,  isOpen: false, animationClass: "animation__right" },

    ]);
  }, [selectedLanguage]);



  const elementRefs = {
    title: useRef(null),
  };

  const toggleCard = (clickedIndex) => {
    const updatedCards = cards.map((card, index) => {
      if (index === clickedIndex) {
        return {
          ...card,
          isOpen: card.isAnimated ? true : !card.isOpen,
        };
      } else if (card.isOpen && !card.isAnimated) {
        return {
          ...card,
          isOpen: false,
        };
      }
      return { ...card };
    });

    setCards(updatedCards);
    setOpenCardIndex(clickedIndex);
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  


  return (
    <section className="faq" id='faq'>
      <h2 className="faq__title secondary-title" ref={elementRefs.title}>
        FAQ
      </h2>

      <div className="faq__content">
        {cards.map((card, index) => (
          <QuestionCard
            key={card.question}
            question={card.question}
            answer={card.answer}
            isOpen={card.isOpen}
            toggleCard={() => toggleCard(index)}
            animationClass={card.animationClass}
            isAnimated={card.isAnimated}
          />
        ))}
      </div>
    </section>
  );
}

export default FAQ;
