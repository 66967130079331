import React, { useEffect, useState, useRef } from "react";
import "./style/products.css";
import ProductCard from "../UI/ProductCard/ProductCard";

import product1 from "./img/product-01.jpg";
import product2 from "./img/product-02.jpg";
import product3 from "./img/product-03.jpg";
import product4 from "./img/product-04.jpg";
import product5 from "./img/product-05.jpg";
import product6 from "./img/product-06.jpg";
import product7 from "./img/product-07.jpg";
import product8 from "./img/product-08.jpg";
import product9 from "./img/product-09.jpg";
import product10 from "./img/product-10.jpg";
import product11 from "./img/product-11.jpg";
import product12 from "./img/product-12.jpg";
import product13 from "./img/product-13.jpg";
import product14 from "./img/product-14.jpg";
import product15 from "./img/product-15.jpg";
import product16 from "./img/product-16.jpg";
import product17 from "./img/product-17.jpg";
import product18 from "./img/product-18.jpg";
import product19 from "./img/product-19.jpg";
import product20 from "./img/product-20.jpg";
import product21 from "./img/product-21.jpg";
import product22 from "./img/product-22.jpg";
import product23 from "./img/product-23.jpg";

import referenceSRC from "./img/reference.jpg";

import logo from "./../main/img/logo.svg";

const Products = ({ selectedLanguage, languageTexts }) => {
  const [popup, setPopup] = useState(false);
    

  const handleCertificateClick = (e) => {
      setPopup(!popup);
  }



  const elementRefs = {
    title: useRef(null),
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
    card4: useRef(null),
    card5: useRef(null),
    card6: useRef(null),
    card7: useRef(null),
    card8: useRef(null),
    card9: useRef(null),
    card10: useRef(null),
    card11: useRef(null),
    card12: useRef(null),
    card13: useRef(null),
    card14: useRef(null),
    card15: useRef(null),
    card16: useRef(null),
    card17: useRef(null),
    card18: useRef(null),
    card19: useRef(null),
    card20: useRef(null),
    card21: useRef(null),
    card22: useRef(null),
    card23: useRef(null),
    reference: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight) {
            element.classList.add("animate");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [elementRefs]);

  return (
    <div className="products__wrapper">
      <section className="products" id="products">
        <h2 className="products__title secondary-title" ref={elementRefs.title}>
          {languageTexts[selectedLanguage].products.title}
        </h2>

        <div className="products__container">
          <ProductCard
            refPath={elementRefs.card1}
            srcPath={product1}
            modifier="gradient"
            productName={languageTexts[selectedLanguage].products.product1}
          />
          <ProductCard
            refPath={elementRefs.card2}
            srcPath={product2}
            productName={languageTexts[selectedLanguage].products.product2}
          />
          <ProductCard
            refPath={elementRefs.card3}
            srcPath={product3}
            productName={languageTexts[selectedLanguage].products.product3}
          />
          <ProductCard
            refPath={elementRefs.card4}
            srcPath={product4}
            productName={languageTexts[selectedLanguage].products.product4}
          />
          <ProductCard
            refPath={elementRefs.card5}
            srcPath={product5}
            productName={languageTexts[selectedLanguage].products.product5}
          />
          <ProductCard
            refPath={elementRefs.card6}
            srcPath={product6}
            productName={languageTexts[selectedLanguage].products.product6}
          />
          <ProductCard
            refPath={elementRefs.card7}
            srcPath={product7}
            productName={languageTexts[selectedLanguage].products.product7}
          />
          <ProductCard
            refPath={elementRefs.card8}
            srcPath={product8}
            productName={languageTexts[selectedLanguage].products.product8}
          />
          <ProductCard
            refPath={elementRefs.card9}
            srcPath={product9}
            productName={languageTexts[selectedLanguage].products.product9}
          />
          <ProductCard
            refPath={elementRefs.card10}
            srcPath={product10}
            productName={languageTexts[selectedLanguage].products.product10}
          />
          <ProductCard
            refPath={elementRefs.card11}
            srcPath={product11}
            productName={languageTexts[selectedLanguage].products.product11}
          />
          <ProductCard
            refPath={elementRefs.card12}
            srcPath={product12}
            productName={languageTexts[selectedLanguage].products.product12}
          />
          <ProductCard
            refPath={elementRefs.card13}
            srcPath={product13}
            productName={languageTexts[selectedLanguage].products.product13}
          />
          <ProductCard
            refPath={elementRefs.card14}
            srcPath={product14}
            productName={languageTexts[selectedLanguage].products.product14}
          />
          <ProductCard
            refPath={elementRefs.card15}
            srcPath={product15}
            productName={languageTexts[selectedLanguage].products.product15}
          />
          <ProductCard
            refPath={elementRefs.card16}
            srcPath={product16}
            productName={languageTexts[selectedLanguage].products.product16}
          />
          <ProductCard
            refPath={elementRefs.card17}
            srcPath={product17}
            productName={languageTexts[selectedLanguage].products.product17}
          />
          <ProductCard
            refPath={elementRefs.card18}
            srcPath={product18}
            productName={languageTexts[selectedLanguage].products.product18}
          />
          <ProductCard
            refPath={elementRefs.card19}
            srcPath={product19}
            productName={languageTexts[selectedLanguage].products.product19}
          />
          <ProductCard
            refPath={elementRefs.card20}
            srcPath={product20}
            productName={languageTexts[selectedLanguage].products.product20}
          />
          <ProductCard
            refPath={elementRefs.card21}
            srcPath={product21}
            productName={languageTexts[selectedLanguage].products.product21}
          />
          <ProductCard
            refPath={elementRefs.card22}
            srcPath={product22}
            productName={languageTexts[selectedLanguage].products.product22}
          />
          <ProductCard
            refPath={elementRefs.card23}
            srcPath={product23}
            productName={languageTexts[selectedLanguage].products.product23}
          />
        </div>

        <div className="products__reference" ref={elementRefs.reference}>
            <div onClick={handleCertificateClick} className="products__reference__certificate__box">
                <img className='products__reference__img' src={referenceSRC} alt="certificate" />
            </div>
        </div>

        <div onClick={handleCertificateClick} className={`products__reference__popup-overlay ${popup ? '_active' : "_hide"}`}>
            <div className="products__reference__popup-wrapper">
                <img className='products__reference__img' src={referenceSRC} alt="certificate" />
            </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
