import React, { useEffect, useRef, useState } from 'react';
import "./style/home.css";
import logo from "../main/img/logo.svg";

const Home = ({languageTexts, setSelectedLanguage, selectedLanguage}) => {
  const [languageBoxesVisible, setLanguageBoxesVisible] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const myRef = useRef(null);

  let burgerRow1 = burgerOpen ? "box__burger-row box__burger-row1 box__burger-row1Show" : "box__burger-row box__burger-row1";
  let burgerRow2 = burgerOpen ? "box__burger-row box__burger-row2 box__burger-row2Show" : "box__burger-row box__burger-row2";
  let burgerRow3 = burgerOpen ? "box__burger-row box__burger-row3 box__burger-row3Show" : "box__burger-row box__burger-row3";
  let burgerPopup = burgerOpen ? "home__popup home__popup-open" : "home__popup";

  let burgerNavigation = burgerOpen ? "box__navigation box__navigation-open" : "box__navigation";

  const handleChangeLanguage = (language) => {

    const myElement = document.getElementById('popopLanguage');


    if (myElement) {
      myElement.classList.add("home__language-open");
    }

    setTimeout(() => {
      myElement.classList.remove("home__language-open");
      document.body.classList.remove("body-hidden"); 
    }, 2000);

    setSelectedLanguage(language);
    console.log(language);
  
    const url = new URL(window.location.href);
    url.pathname = `/${language}${url.pathname.replace(/\/(ua|cz|eu)/, '')}`;
    url.pathname = url.pathname.replace(/\/$/, '');
    window.history.replaceState({}, '', url.toString());
    
  };
  
  useEffect(() => {
    const path = window.location.pathname.toLowerCase();
    if (path === '/' || path.startsWith('/eu')) {
      setSelectedLanguage('eu');
    } else if (path.startsWith('/cz')) {
      setSelectedLanguage('cz');
    } else {
      console.log("hello world");
      setSelectedLanguage('eu'); 
      window.history.replaceState({}, '', "/eu");

    }
  }, [setSelectedLanguage]);
  
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      document.body.classList.remove("body-hidden"); 
    }, 3500);

    return () => {
      clearTimeout(popupTimer);
    };
  }, []);


  const hadleOpenLanguage = () => {
    setLanguageBoxesVisible(!languageBoxesVisible); 
  }

  const hadleOpenBurger = () => {
    setBurgerOpen(!burgerOpen);
    document.body.classList.toggle("body-hidden", !burgerOpen); 
    myRef.current.scrollIntoView();
  }

  // nav scroll
  const handleScrollToSection = (sectionId, isWorkProcess) => {
    setBurgerOpen(false);
  
    document.body.classList.remove("body-hidden");
  
    if (!sectionId || sectionId === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  
    const sectionRef = document.getElementById(sectionId);
    if (sectionRef) {
      const sectionTop = sectionRef.getBoundingClientRect().top + window.pageYOffset;
      // Calculate the offset position
      const offset = isWorkProcess ? 250 : 0;
      const targetPosition = sectionTop + offset;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    }
  };
  

  const [isScrolled, setIsScrolled] = useState(false);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    const distanceToBottom = documentHeight - (scrollPosition + windowHeight);
  
    if (scrollPosition > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  
    if (distanceToBottom < 40) {
      setIsScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Плавная прокрутка
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    // Fixed Header
    const [scrollingDown, setScrollingDown] = useState(false);
    const [isUserOnTop, setIsUserOnTop] = useState(true);
  
    useEffect(() => {
      let prevScrollPos = window.pageYOffset;
  
      const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setScrollingDown(prevScrollPos < currentScrollPos);
        prevScrollPos = currentScrollPos;
  
        currentScrollPos > 0 ? setIsUserOnTop(false) : setIsUserOnTop(true)
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  

  return (
    <section ref={myRef} className='home'>

      <div onClick={scrollToTop} className={`home__scroll-top ${isScrolled ? "_open" : ""}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M20.0001 10V30M20.0001 10L11.6667 18.3333M20.0001 10L28.3334 18.3333" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>

      <div className="home__language" id='popopLanguage'></div>

      <div className="page__popup">
        <div className="logo">

        </div>
      </div>

      <div className={burgerPopup}></div>

      <div className="home__header--phantom"></div>

      <header
       className={`home__header ${!scrollingDown ? "_header-show" : "_header-hide"} ${burgerOpen ? "_hide-bg" : ""}`}
       style={{
        backgroundColor: isUserOnTop ? "transparent" : "rgba(0, 0, 0, 0.4)",
        backdropFilter: isUserOnTop ? "blur(0px)" : "blur(5px)",

        backgroundColor: burgerOpen ? "transparent" : "rgba(0, 0, 0, 0.4)",
        backdropFilter: burgerOpen ? "blur(0px)" : "blur(5px)",
      }}>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className="box">
          <nav className={burgerNavigation}>
             <ul>
              <li onClick={() => handleScrollToSection('about-us')}>{languageTexts[selectedLanguage].home.nav1}</li>
              <li onClick={() => handleScrollToSection('products')}>{languageTexts[selectedLanguage].home.nav2}</li>
              <li onClick={() => handleScrollToSection('work', true)}>{languageTexts[selectedLanguage].home.nav3}</li>
              <li onClick={() => handleScrollToSection('faq')}>{languageTexts[selectedLanguage].home.nav4}</li>
              <li onClick={() => handleScrollToSection('contact')}>{languageTexts[selectedLanguage].home.nav5}</li>
            </ul>
          </nav>

          <div className="box__language" onClick={hadleOpenLanguage}>
            <div className="box__language-box">
              <div className={`box__language-flat box__language-main box__language-${selectedLanguage}`}>
              </div>
              <div className={`box__language-arrow  ${languageBoxesVisible ? "box__language-arrowimgRow" : "box__language-arrowimg"}`}></div>
            </div>

            {selectedLanguage !== 'cz' && (
              <div className={`box__language-box ${languageBoxesVisible ? "box__language-boxShow" : "box__language-boxHide"}`} onClick={() => handleChangeLanguage('cz')}>
                <div className="box__language-flat box__language-main box__language-cz"></div>
                <div className="box__language-arrow box__language-arrow"></div>
              </div>
            )}

            
            {selectedLanguage !== 'eu' && (
              <div className={`box__language-box ${languageBoxesVisible ? "box__language-boxShow" : "box__language-boxHide"}`} onClick={() => handleChangeLanguage('eu')}>
                <div className="box__language-flat box__language-eu"></div>
                <div className="box__language-arrow"></div>
              </div>
            )}

          </div>

          <div className="box__burger" onClick={hadleOpenBurger}>
              <span className={burgerRow1}></span>
              <span className={burgerRow2}></span>
              <span className={burgerRow3}></span>

          </div>

        </div>
      </header>
      
      <main className="main">
        <div className="main__element">
          <h1 className='main__element-title'>{languageTexts[selectedLanguage].home.title1} <br /> 
          <span className='main__element-color'>{languageTexts[selectedLanguage].home.title2}</span> <br /> 
          {languageTexts[selectedLanguage].home.title3}</h1>
        </div>

        <div className="main__container">
          <p className='main__container-descripiton'>{languageTexts[selectedLanguage].home.description}</p>

        </div>
      </main>

    </section>
  );
}

export default Home;
