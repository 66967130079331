import Footer from "./components/Footer/Footer.jsx";
import Contact from "./components/Contact/Contact.jsx";
import WorkProcess from "./components/WorkProcess/WorkProcess.jsx";
import Products from "./components/Products/Products.jsx";
import AboutUs from "./components/AboutUs/AboutUs";
import Home from "./components/Home/Home.jsx";
import { useState } from "react";
import FAQ from "./components/FAQ/FAQ.jsx";
import Gallery from "./components/Gallery/Gallery.jsx";
import Certificate from "./components/Certificate/Certificate.jsx";

function App() {

  document.body.classList.add("body-hidden"); 
  const [selectedLanguage, setSelectedLanguage] = useState('eu');
  
  const languageTexts = {
    eu: {
      home: {
        title1: "Company G95 Ltd.",
        title2: "Your business gateway to metallurgy and mining equipment",
        title3: "between China and Europe",
        nav1: "About us",
        nav2: "Products",
        nav3: "How we work",
        nav4: "FAQ",
        nav5: "Contacts",
        description: "Metallurgy and mining equipment, specialized individual production, bearings, rings, gears, rolls, forged round steel, traverses, universal couplings, etc.",
      },
      products: {
        title: "Products",
        product1: "Special individual production",
        product2: "Bearings",
        product3: "Rings",
        product4: "Gears",
        product5: "Rolls",
        product6: "Forged round steel",
        product7: "Traverses",
        product8: "Guide screws",
        product9: "Universal couplings",
        product10: "Hammer heads for crusher",
        product11: "Heating furnace",
        product12: "Continuous casting machine (CCM)",
        product13: "Tube mills",
        product14: "Bar and wire rolling mills",
        product15: "Gyratory Crusher",
        product16: "Jaw Crusher",
        product17: "Single Cylinder Hydraulic Cone Crusher",
        product18: "Multi Cylinder Cone Crusher",
        product19: "Impact Crusher",
        product20: "Vertical Axis Impact Crusher",
        product21: "Vibrate Screen",
        product22: "Vibrating rod feeders",
        product23: "Grinding Equipment (Ball Mill, Rod Mill)",
      },
      about: {
        title: "About Us",
        desription1: "The company G95 Ltd. specializes in supplying high-quality Chinese metallurgical and mining products and machinery to the EU markets. We focus on metallurgy and mining equipment, specialized individual production, bearings, rings, gears, rolls, forged round steel, traverses, universal couplings, etc. Our clients are steel mills, mining companies, cement plants, and foundries.",
        desription2: "We have been operating in the Ukrainian metallurgical market since 2018. In early 2023, we transformed into G95 Ltd. Our mission is to simplify the negotiation process and assist in establishing cooperation between Chinese manufacturers and EU markets. We strive for fast and professional communication and offer a competitive pricing policy.",
        subTitle: "G95 Ltd. is the exclusive European representative of Shenyang Sanland Mining Engineering Co., Ltd., which provides high-quality Chinese metallurgical products and mining equipment. The company specializes in technology development, design, and overall construction of projects for the mining industry. Equipment can process hard and soft rock, industrial minerals, and recycling material. Our equipment and parts are used globally. Due to our experience in R&D design engineering manufacturing and service ensures the high efficiency of our equipment and parts. Shenyang Sanland Mining Engineering Co., Ltd. applies and works to follow ISO9001, ISO14001, and ISO45001 quality management systems."
      },

      work: {
        title: "How we work: 4 steps to start cooperation:",
        subTitle1: "01 - Contact us",
        description1: "Leave us a message or straight call us.",
        subTitle2: "02 – Order form",
        description2: "Our manager will send you a short non-binding order form. Please fill out this form with as much detail as possible, including technical drawings and quantities requested.",
        subTitle3: "03 – Commercial offer",
        description3: "Based on your filled non-binding order form, we will analyze our production possibilities and present a commercial offer.",
        subTitle4: "04 – Cooperation",
        description4: "If you accept our commercial offer, we will initiate further steps in cooperation according to the specific business.",
      },

      gallery: {
        title: "Gallery"
      },

      faq: {
        title: "FAQ",
        question1: "ORIGIN: What is the origin of the goods?",
        answer1: "Made in China by our exclusive partner Shenyang Sanland Mining Engineering Co., Ltd., or another of our verified Chinese partners.",

        question2: "CONTRACT PARTIES: Who closes the contract of sale?",
        answer2: "The contract will be closed between the EU customer and our company G95 Ltd.",
       
        question3: "PAYMENT: What are the payment terms?",
        answer3: "Prepayment of the 10-30 % before shipment, or starting a special individual production (we can ensure pre-shipment inspection by independent company SGS, or another).",
        answer3Description: "The remaining amount will be paid after the delivery and quality control by the Buyer in the EU. The exact payment terms will be specified for each customer individually before the closing of a Contract.",
    
        question4: "DELIVERY: Which INCOTERMS term do you work with?",
        answer4: "Based on our experience in logistics, we can provide CIF or DAP delivery into the EU. During shipment, you will be regularly informed about the current situation and expected delivery date.",

        question5: "PRODUCTION + DELIVERY TIME: How long does a production and delivery to Europe take?",
        answer5: "Usually, the process of the production and delivery to the EU can take 40-90 days from the moment after the payment.",
        answer5Description: "In the case of special individual production, the exact production and delivery date will be specified before closing the Contract.",

        question6: "WARRANTY + QUALITY CLAIMS + ARBITRATION: How do you guarantee the quality? Process of eventual claims and arbitration?",
        answer6: "The warranty period extends up to 12 months, contingent upon the specific product or machinery. The exact warranty period will be determined in the contract.",
        answer6Description1: "Claims will be solved in accordance with the contract conditions. We can ensure pre-shipment inspection by an independent company SGS, or another and minimise the risk.",
        answer6Description2: "In case of the arbitration, the process will be in one of the EU countries (usually Austrian Arbitration and laws).",
      },
    
      contact: {
        title: "Contact",
        subTitle1: "Name and Surname",
        input1: "Your name",

        subTitle2: "Email",
        input2: "Your email",

        subTitle3: "Company",
        input3: "Company name",

        subTitle4: "Phone",
        input4: "Your phone",

        subTitle5: "Message to us",
        input5: "Leave us a message about what interests you. Please send us your technical specification.",

        error1: "was not found",
        error2: "Invalid email",

        button: "Send",
        popupButton: "Successfully Sent",
        cart1: {
          name: "Ondrej Kacirek",
          position: "Executive director",
        },
        cart2: {
          name: "Matej Kacirek",
          position: "English speaking manager for the EU",
        },
        cart3: {
          name: "Yevhen Matviienko",
          position: "Technology director",
        },
        cart4: {
          name: "Natalia Smirnova",
          position: "Logistics manager",
        },

        cart5: {
          sales: "Sales",
          invoices: "Invoices",
        },

        description: "Company G95 Ltd. Your business gateway to metallurgy and mining equipment between China and Europe.",
        titleNav: "Navigation",
        titleNav2: "Company details",
        titleNav3: "Contacts",
        nav1: "About us",
        nav2: "Products",
        nav3: "How we work",
        nav4: "FAQ",
        nav6: "Contacts",
        subDescription: "G95 Ltd., Holubova 746, 530 03 Pardubice, Czech Republic is registered in the Commercial register with Municipal Court in Hradec Králové, section C 50768.",
        footerBottom: "All rights reserved.",
        footerCompanyCode: "CIN",
        companyName: "G95 Ltd.",
        footerDay1: "Mon",
        footerDay2: "Fri",
        producer: "Design and production -"
      }
      
    },
    cz: { 
      home: {
        title1: "Společnost G95 s.r.o.",
        title2: "Vaše obchodní spojení v metalurgii a těžebních zařízeních",
        title3: "mezi Čínou a trhy EU ",
        nav1: "O nás",
        nav2: "Produkty",
        nav3: "Jak pracujeme",
        nav4: "FAQ",
        nav5: "Kontakty",
        description: "Metalurgické a těžební zařízení, specializovaná individuální výroba, ložiska, kroužky, ozubená kola, válce, kovaná kruhová ocel, traverzy, univerzální spojky aj.",
      },
      products: {
        title: "Produkty",
        product1: "Speciální individuální výroba",
        product2: "Ložiska",
        product3: "Kroužky",
        product4: "Ozubená kola",
        product5: "Válce",
        product6: "Kovaná kruhová ocel ",
        product7: "Traverzy",
        product8: "Vodící šrouby",
        product9: "Univerzální spojky",
        product10: "Hlava kladiva pro drtiče",
        product11: "Topné pece",
        product12: "Linka na kontinuální lití (CCM)",
        product13: "Stroje na výrobu trubek",
        product14: "Stroj na válcování tyčí a drátů",
        product15: "Drtiče rotační",
        product16: "Drtiče čelisťové",
        product17: "Jednoválcový hydraulický kuželový drtič",
        product18: "Víceválcový kuželový drtič",
        product19: "Nárazový drtič",
        product20: "Nárazový drtič s vertikální osou",
        product21: "Vibrační síto",
        product22: "Vibrační tyčový podavač",
        product23: "Brusné zařízení (Kulový a tyčový mlýn)",
      },
      about: {
        title: "O nás",
        desription1: "Společnost G95 s.r.o. se specializuje na dodávky vysoce kvalitních čínských metalurgických a těžebních výrobků a zařízení na trhy EU. Zaměřujeme se na metalurgii a těžební zařízení, specializovanou individuální výrobu, ložiska, kroužky, ozubená kola, válce, kovanou kruhovou ocel, traverzy, univerzální spojky aj. Našimi zákazníky jsou ocelárny, těžební společnosti, cementárny a slévárny.",
        desription2: "Od roku 2018 působíme na ukrajinském metalurgickém trhu. Začátkem roku 2023 jsme se transformovali do společnosti G95 s.r.o. Naším posláním je zjednodušit proces vyjednávání a pomoci při navazování spolupráce mezi čínskými výrobci a trhy EU. Snažíme se o rychlou a profesionální komunikaci a nabízíme konkurenceschopnou cenovou politiku. ",
        subTitle: "Společnost G95 s.r.o. je výhradním evropským zástupcem společnosti Shenyang Sanland Mining Engineering Co., Ltd., která dodává vysoce kvalitní čínské hutní výrobky a těžební zařízení. Společnost se specializuje na vývoj technologií, projektování a celkovou realizaci projektů pro těžební průmysl. Zařízení mohou zpracovávat tvrdé i měkké horniny, průmyslové minerály a recyklovatelný materiál. Naše zařízení a díly se používají po celém světě. Díky našim zkušenostem v oblasti výzkumu a vývoje, konstrukce, výroby a servisu je zajištěna vysoká účinnost našich zařízení a dílů. Společnost Shenyang Sanland Mining Engineering Co., Ltd. uplatňuje a pracuje v souladu se systémy řízení kvality ISO9001, ISO14001 a ISO45001."
      },

      work: {
        title: "Jak pracujeme, 4 kroky k zahájení spolupráce:",
        subTitle1: "Kontaktujte nás",
        description1: "Zanechte nám zprávu, nebo nám rovnou zavolejte.",
        subTitle2: "02	- Objednávkový formulář",
        description2: "Náš manažer Vám zašle krátký nezávazný objednávkový formulář. Vyplňte prosím tento formulář s co nejvíce podrobnostmi, včetně technických výkresů a požadovaného množství.",
        subTitle3: "03	- Obchodní nabídka",
        description3: "Na základě Vámi vyplněného nezávazného objednávkového formuláře provedeme analýzu našich výrobních možností a předložíme Vám obchodní nabídku.",
        subTitle4: "04	- Spolupráce",
        description4: "Pokud naši obchodní nabídku přijmete, zahájíme další kroky spolupráce podle konkrétního obchodu.",
      },

      gallery: {
        title: "Galerie"
      },

      faq: {
        title: "FAQ",
        question1: "PŮVOD: Jaký je původ zboží?",
        answer1: "Vyrobeno v Číně naším výhradním partnerem Shenyang Sanland Mining Engineering Co., Ltd., nebo jiným naším ověřeným čínským partnerem.",

        question2: "SMLUVNÍ STRANY: Kdo uzavírá kupní smlouvu?",
        answer2: "Smlouva bude uzavřena mezi zákazníkem z EU a naší společností G95 s.r.o.",
       
        question3: "PLATBA: Jaké jsou platební podmínky?",
        answer3: "Platba předem ve výši 10-30 % před odesláním, nebo zahájením speciální individuální výroby (můžeme zajistit přednakládkovou kontrolu zboží od nezávislé společnosti SGS, nebo jiné).",
        answer3Description: "Zbývající částka bude uhrazena po dodání a kontrole kvality ze strany EU kupujícího. Přesné platební podmínky budou stanoveny pro každého zákazníka individuálně před uzavřením smlouvy.",
    
        question4: "DORUČENÍ: S jakým termínem INCOTERMS pracujete?",
        answer4: "Na základě našich zkušeností v logistice můžeme zajistit dodávky CIF nebo DAP do EU. Během přepravy budete pravidelně informováni o aktuální situaci a očekávaném doručení zboží. ",

        question5: "DOBA VÝROBY A DODÁNÍ: Jak dlouho trvá výroba a dodání do Evropy?",
        answer5: "Proces výroby a dodání do EU obvykle trvá 40-90 dní od okamžiku přijetí platby.",
        answer5Description: "V případě speciální individuální výroby bude přesný termín výroby a dodání zboží upřesněn před podepsáním smlouvy.",

        question6: "ZÁRUKA + REKLAMACE KVALITY + ARBITRÁŽ: Jak je garantována kvalita? Proces případných reklamací a arbitráže?",
        answer6: "Záruční doba je v závislosti na konkrétní výrobek nebo stroj až 12 měsíců. Přesná záruční doba bude stanovena ve smlouvě.",
        answer6Description1: "Reklamace budou řešeny v souladu se smluvními podmínkami. Můžeme zajistit přednakládkovou kontrolu zboží od nezávislé společnosti SGS, nebo jiné, a minimalizovat tak rizika.",
        answer6Description2: "V případě arbitráže bude proces probíhat v jedné ze zemí EU (obvykle v rakouském rozhodčím a soudním řízení).",
      },
     
      contact: {
        title: "Kontaktujte nás",
        subTitle1: "Jméno a příjmení",
        input1: "Vaše jméno",

        subTitle2: "Email",
        input2: "Váš email",

        subTitle3: "Společnost",
        input3: "Název společnosti",

        subTitle4: "Telefon",
        input4: "Váš telefon",

        subTitle5: "Vzkaz pro nás",
        input5: "Zanechte nám zprávu o tom, co Vás zajímá. Zašlete nám prosím svou technickou specifikaci.",
        button: "Odeslat můj dotaz.",
        popupButton: "Odesláno úspěšně",

        error1: "nebyl nalezen",
        error2: "Neplatný e-mail",

        cart1: {
          name: "Ondřej Kačírek",
          position: "Jednatel společnosti",
        },
        cart2: {
          name: "Matěj Kačírek",
          position: "Anglicky mluvící manažer pro EU",
        },
        cart3: {
          name: "Yevhen Matviienko",
          position: "Technologický ředitel",
        },
        cart4: {
          name: "Natalia Smirnova",
          position: "Manažer logistiky",
        },

        cart5: {
          sales: "Prodej ",
          invoices: "Fakturace ",
        },

        description: "Společnost G95 s.r.o. Vaše obchodní spojení v metalurgii a těžebních zařízeních mezi Čínou a trhy EU.",
        titleNav: "Navigace",
        titleNav2: "Firemní detaily",
        titleNav3: "Kontakty",
        nav1: "O nás",
        nav2: "Produkty",
        nav3: "Jak pracujeme",
        nav4: "FAQ",
        nav6: "Kontakty",
        subDescription: "G95 s.r.o., Holubova 746, 530 03 Pardubice, Česká republika, je zapsána v obchodním rejstříku u Městského soudu v Hradci Králové, oddíl C 50768.",
        footerBottom: "Všechna práva vyhrazena.",
        footerCompanyCode: "IČO",
        companyName: "G95 s.r.o.",
        footerDay1: "Po",
        footerDay2: "Pa",
        producer: "Návrh a výroba webu -"
      }
    }
  };



  return (

      <div className="App">
        <Home
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <AboutUs
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <Products
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <WorkProcess
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <Certificate/>
        <FAQ
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <Gallery 
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <Contact
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
        <Footer
          selectedLanguage={selectedLanguage}
          languageTexts={languageTexts}
        />
    </div>
  );
}

export default App;